.navbar {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    background-color: #131F48;
}

.nav-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 20px;
}

@media (max-width: 760px) {
    .nav-list {
        gap: 10px;
    }
}

.nav-item {
    margin-right: 20px;
}

.nav-link {
    text-decoration: none;
    color: #ffffff;
    font-weight: bold;
    font-size: 24px;
    transition: color 0.3s ease-in-out;
}

@media (max-width: 760px) {
    .nav-link {
        font-size: 18px;
    }
}

@media (min-width: 761px) {
    .nav-link {
        font-size: 24px;
    }
}

.nav-link:hover {
    color: #99002F;
}
