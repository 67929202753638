.sort {
  margin-left: 20px;
}

.price-entry-titles {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  font-weight: bold;
  align-items: center;
}
  
.entry-item-title {
  flex: 1;
  text-align: center;
}

@media (min-width: 760px) {
  .price-entry-titles {
    font-size: 24px;
  }
}

#title {
  text-align: center;
}

.bullets {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 22px;
}

@media (max-width: 760px) {
  .bullets {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 18px;
  }
}

/* Add this to your existing stylesheet */

#sortDropdown {
  padding: 8px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
  border: 2px solid #ccc; /* Add a border */
  border-radius: 4px; /* Add some border-radius for rounded corners */
  margin-right: 10px; /* Adjust margin as needed */
  cursor: pointer; /* Add a pointer cursor on hover */
}

/* Style the dropdown arrow */
#sortDropdown::after {
  content: ' ▼'; /* Unicode for down arrow */
  font-size: 12px; /* Adjust font size as needed */
  margin-left: 5px; /* Adjust margin as needed */
}

/* Style dropdown options */
#sortDropdown option {
  background-color: #f9f9f9; /* Background color for options */
  color: #333; /* Text color for options */
}

/* Style the dropdown when hovered */
#sortDropdown:hover {
  border-color: #888; /* Border color on hover */
}

/* Style the dropdown when focused */
#sortDropdown:focus {
  outline: none; /* Remove the default focus outline */
  border-color: #4caf50; /* Border color when focused */
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.6);
}

#sortDropdown:hover,
#sortDropdown:focus {
  background-color: #f9f9f9; /* Light background color on hover/focus */
}