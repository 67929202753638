.home-container {
  text-align: center;
  padding: 20px;
  padding-top: 0;
}

.builds-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow-x: auto;
    gap: 10px;
}

.flip-all-button {
    background-color: #99002F;
    color: white;
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 40px;
    transition: background-color 0.3s ease-in-out;

  }
  
  .flip-all-button:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #D50141;
  }