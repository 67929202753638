.footer {
    background-color: #131F48;
    color: white;
    padding: 20px 10px;
    text-align: center;
    margin-top: 20px;
  }
    
  .footer-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .warning {
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.5;
  }
  
  .warning p {
    margin-bottom: 10px;
  }
  
  .underlined-text {
    text-decoration: underline;
  }