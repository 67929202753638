/* Resell page styles */

.resell {
    text-align: center;
}

.resell-container {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-evenly;
}

.filter-sort-container {
    margin-bottom: 10px;
}

.filter-sort-container select {
    padding: 10px;
    margin: 0 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #fff;
}

/* Style for the create posting button */
.resell button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-bottom: 10px;
}

.resell button:hover {
    background-color: #0056b3;
}

.noticeb {
    text-align: left;
    font-size: 22px;
    padding-inline-start: 30px;
    margin-left: 30px;
    margin-top: 0px;
    margin-bottom: 5px;
}
  
@media (max-width: 760px) {
.noticeb {
    font-size: 18px;
    margin-left: 0px;
    }
}