.card-div {
  perspective: 1000px;
  width: 350px;
  height: 432px;
  border-radius: 15px;
  border: 6px solid black;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.7s;
  cursor: pointer;
}

@media (max-width: 760px) {
  .card-div {
      width: 150px;
      height: 185px;
      border-radius: 6px;
      border: 3px solid black;
  }
}

.card-div.flipped {
  transform: rotateY(180deg);
}

.build-card {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
}

.build-card-front,
.build-card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.build-card-front {
  background: #99002F;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

@media (max-width: 760px) {
  .build-card-front {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

.build-card-back {
  background: #99002F;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  transform: rotateY(180deg);
}

@media (max-width: 760px) {
  .build-card-back {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

img {
  width: 100%;
  height: 362px; /*must sum to total height with .card-name */
  object-fit: cover;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

@media (max-width: 760px) {
  img {
    height: 155px;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }
}

.card-name {
  margin: 0;
  color: #ffffff;
  height: 70px; /*must sum to total height with img */
  text-shadow: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 760px) {
  .card-name {
      height: 30px;
  }
}

h2 {
  margin: 0;
  font-size: 30px;
}

@media (max-width: 760px) {
  h2 {
      font-size: 13px;
  }
}