.price-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 5px solid #000;
    border-radius:10px;
    padding: 8px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 8px;
    font-size: 25px;
    background: #99002F;
    color: #ffffff;
    text-shadow: rgba(0, 0, 0, 0.5); 
    font-weight: bold;
    /* box-shadow: 0 4px 0 #888888; */
    margin-bottom: 8px;
}

@media (max-width: 760px) {
  .price-entry {
    font-size: 14px;
    margin-left: 8px;
    margin-right: 8px;
    border: 3px solid #000;
    /* box-shadow: 0 2px 0 #888888; */
  }
}
  
  .entry-item {
    flex: 1;
    text-align: center;
  }
  
  .click {
    cursor: pointer;
  }