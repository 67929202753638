.map {
    text-align: center;
}

.map-img {
    max-width: 80%;
    height: auto;
    border-radius: 20px;
}

@media (max-width: 760px) {
    .map-img {
        max-width: 90%;
        border-radius: 10px;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}
